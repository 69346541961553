<template>
  <div class="news-list-wrapper">

    <h1 class="title"><img class="icon" src="@/assets/news_icon.png" alt=""><span>新 闻</span>
    </h1>
    <div class="news-list" v-loading="loading">
      <div class="news-article" v-for="(item, index) in newsData" :key="index">
        <!-- <div class="article-media">
          <img :src="item.imageUrl || defaultimg" alt="">
        </div> -->
        <div class="article-content" @click="toDetail(item.id)">
          <div class="article-header">
            <h2>{{item.title}}</h2>
            <div class="article-meta"><i
                class="el-icon-time"></i>{{item.date | dateformat('YYYY-MM-DD HH:mm:ss')}}</div>
          </div>
          <div class="article-body">
            <p>{{item.content}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="paginationConfig.currentPage" :page-sizes="[10, 20, 50]"
        :page-size="paginationConfig.pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="paginationConfig.total">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import { listNews, countPosts } from "@/api/news";
export default {
  name: "NewsList",
  components: {},
  data() {
    return {
      loading: false,
      newsData: [],
      paginationConfig: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      defaultimg: require("../../assets/default.png"),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toDetail(id) {
      this.$router.push(`/news/${id}`);
    },
    getList() {
      this.loading = true;
      const { currentPage, pageSize } = this.paginationConfig;
      let data = {
        first: (currentPage - 1) * pageSize,
        max: pageSize,
      };
      listNews(data)
        .then((response) => {
          countPosts().then((data) => {
            this.paginationConfig.total = data;
          });
          this.newsData = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      this.paginationConfig.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.paginationConfig.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.news-list-wrapper {
  .title {
    font-size: 24px;
    margin: 18px 0;
    .icon {
      width: 30px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: bottom;
    }
  }
  .news-list {
    border-top: 1px solid #d2d2d3;
    min-height: 400px;
    .news-article {
      border-bottom: 1px solid #eaecee;
      padding: 20px 0;
      cursor: pointer;
      position: relative;
      &:hover {
        h2 {
          color: #0072d6;
        }
      }
      .article-media {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;

        img {
          object-fit: cover;
          width: 150px;
          height: 100px;
          border: 1px solid #ececec;
        }
      }
      .article-content {
        width: calc(100% - 260px);
        display: inline-block;
        vertical-align: top;
        .article-meta {
          color: #6d6d6d;
          margin: 10px 0;
          i {
            margin-right: 5px;
          }
        }
        .article-body {
          color: #666666;
          margin-top: 10px;
        }
      }
    }
  }
  .pagination {
    text-align: right;
    margin-top: 20px;
  }
}
</style>
