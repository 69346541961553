import { get } from "@/plugins/request";

const prefix = '/v1/posts';

export function listNews(data) {
  return get(`${prefix}`, data)
}

export function newDetial(id) {
  return get(`${prefix}/${id}`)
}

export function countPosts() {
  return get(`${prefix}/count`)
}